/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './assets/css/main.css';
import { Carousel } from 'react-responsive-carousel';

import IMG1 from './img/a.jpg'
// import IMG2 from './img/9.jpg'
// import IMG2 from './img/1.jpg'

// import IMG8 from './img/8.jpg'
// import IMG9 from './img/14.jpg'
// import IMG10 from './img/15.jpg'
import { Pane, Dialog } from 'evergreen-ui'

import Logo from './img/logo.png'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Form, Button, Spinner } from 'react-bootstrap';
import Component from '@reactions/component'
let arr = []

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      color: false,
      colo2: false,
      get_color2: '',
      name: '',
      gov: '',
      city: '',
      phone: '',
      size: '',
      count: 1,
      s40: false,
      s1: false,
      s2: false,
      s3: false,
      s4: false,
      more: false,
      counts: [],
      items: [],
      prcie: 190000,
      price_t: 190000,
    }

    // let value = document.getElementById('countvalue').innerHTML
    // console.log(value);

  }

  get_slectedv2(id, size, cls, index) {
    console.log(id);
    let getClass = document.getElementsByClassName(`${`sizeC|${index}`}`);
    for (let index = 0; index < getClass.length; index++) {
      // getClass[index].style.display = "block";
      getClass[index].style.color = "#008dde";
      getClass[index].style.background = " #fff";
      getClass[index].style.border = "3px solid #008dde";
    }
    let get_slected = document.getElementById(id)
    get_slected.style.color = "#fff";
    get_slected.style.background = " #008dde";
    let obj = {
      id: index,
      size: size,
      color: ''
    }

    let ind = arr.find(e => e.id === index)
    if (ind) {

      arr.find(e => e.id === index).size = size;
      // console.log('newArr', arr.find(e => e.id == index));

      //arr.findIndex((obj => obj.id == index))
      // arr = arr.find(e => e.id == index)
      // arr[0].size=size;
      // arr = arr.filter(e => e.id != index)
      //  arr.push(obj)

      // this.setState({ items: found })
    } else {
      arr.push(obj)

      // this.setState({ items: found })
    }



  }
  select_size(id, size) {
    let getClass = document.getElementsByClassName(`'testc'`);
    console.log(getClass);
    for (let index = 0; index < getClass.length; index++) {
      // getClass[index].style.display = "block";
      getClass[index].style.color = "#008dde";
      getClass[index].style.background = " #fff";
      getClass[index].style.border = "3px solid #008dde";
    }
    let get_slected = document.getElementById(id)
    get_slected.style.color = "#fff";
    get_slected.style.background = " #008dde";
    this.setState({ size: size })
  }
  componentDidMount() {




  }
  check_phone(phone) {
    if (phone.length !== 11) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    let result = phone.substring(0, 4);

    if (result[[0]] != 0) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[[1]] != 7) {

      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[2] != 7 && result[2] != 8 && result[2] != 9 && result[2] != 5) {

      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[[3]] > 6) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }




    return false
  }
  submit() {

    document.getElementById('spinnerdiv').style.display = 'flex'
    document.getElementById('submitbtn').style.display = "none"

    // for (let index = 0; index < this.state.counts.length; index++) {
    //   let get_slected = document.getElementById(id)
    let name = document.getElementById('name').value;
    let phone = document.getElementById('phone').value;
    let city = document.getElementById('city').value;
    let gov = document.getElementById('gov').value;

    let items = []
    // }

    // let name = document.getElementById('name').value;
    // let phone = document.getElementById('phone').value;
    // let city = document.getElementById('city').value;
    // let gov = document.getElementById('gov').value;
    // let size = this.state.size
    // let color = this.state.get_color2

    if (!name) {
      window.alert('يرجى ادخال الاسم')

      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"

      return -1;


    }

    if (this.check_phone(phone)) {

      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }

    if (!gov || gov === "none") {
      window.alert('يرجى ادخال المحافظة')
      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }
    if (!city) {
      window.alert('يرجى ادخال المنطقة')
      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }

    let price = this.state.price_t


    let obj = {
      name: 'IPAD-5',
      count: 1
    }
    items.push(obj)
    axios.post('https://api.enfirad.com/users/web/order/v2', {
      name: name,
      gov: gov,
      city: city,
      phone: phone,
      page: 1,
      price: price / 1000,
      notes: "",
      items: items,
      notes2: "(IPAD-5 * 1)"
    })
      .then(function (response) {
        window.location.href = "/done";
      })
      .catch(function (error) {
        document.getElementById('spinnerdiv').style.display = 'none'
        document.getElementById('submitbtn').style.display = "flex"
        console.log(error);
      });

  }



  render() {
    return (
      <div className="App">

        <main className="main">
          <div className="page-header breadcrumb-wrap" style={{ display: 'felx', alignItems: "center", }}>
            <center>
              <img width={75} height={75} src={Logo} alt="" />
              <h3>عيون المستقبل</h3>
            </center>

          </div>
          <section className="mt-60 mb-60">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="product-detail accordion-detail">
                    <div className="row mb-50">
                      <div className="col-md-6 col-sm-12 col-xs-12">

                        <Carousel autoPlay infiniteLoop >

                          <div>
                            <img src={IMG1} alt='im' />
                          </div>


                          {/* <div>
                            <img src={IMG2} alt='im' />
                          </div> */}



                        </Carousel>
                        <div className="single-social-share clearfix mt-50 mb-15">
                          <div className="mobile-social-icon wow fadeIn  mb-sm-5 mb-md-0 animated">

                            {/* <a className="instagram" style={{ backgroundColor: "#25D366", border: "1px solid #25D366" }} href="https://wa.me/9647711128316?text=%D9%87%D9%84%20%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D9%81%D8%B3%D8%A7%D8%B1%20%D8%B9%D9%86%20%D8%AD%D8%AC%D8%B2%20%D9%85%D9%86%D8%AA%D8%AC%20%D8%A7%D9%84%D8%B3%D9%85%D8%A7%D8%B9%D8%A9"><i className="fab fa-whatsapp"></i></a> */}

                          </div>
                        </div>

                      </div>
                      <div className="col-md-6 col-sm-12 col-xs-12" style={{ textAlign: "right" }} >
                        <div className="detail-info" >
                          <h2 className="title-detail">ابااااد 5</h2>
                          <div style={{ width: '100%', disply: 'flex', justfyContent: 'flex-end' }}>
                            <div className="product-detail-rating" >

                              <div className="product-rate-cover text-end">
                                <div className="product-rate d-inline-block">
                                  <div className="product-rating" style={{ width: '90%' }}>
                                  </div>
                                </div>
                                <span className="font-small ml-5 text-muted"> (170k reviews)</span>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix product-price-cover">
                            <div className="product-price primary-color float-left">
                              <ins><span className="text-brand"> قطعة 190,000 شامل اجور التوصيل</span></ins>

                              <ins><span className="old-price font-md ml-15">دينار عراقي 380,000 </span></ins>
                              <div className="save-price  font-md color3 ml-15" style={{ color: 'red', fontSize: '25px', marginTop: '20px' }}>50% خصم</div>
                            </div>
                          </div>
                          <div className="bt-1 border-color-1 mt-15 mb-15"></div>
                          <div className="short-desc mb-30">
                            {/* <p>
                              ابتوب الامريكي لمس ويمكن ان يكون ايباد  وبسعر خرافي 125 الف فقط مع الشاحن وماوس والجنطه والتوصيل مجاني لكافه محافظات العراق - تنفع لطلبه والمكاتب واصحاب الدراسات واصحاب الاعمال الميدانيه متعدده الاستخدام لصغار والكبار وبضمان حقيقي
                              المواصفات
                              تعمل للبرامج الدراسيه والالعاب والتصفح واليوتيوب تستعمل لبرامج الطباعه تحتوي على صوت عالي بلفيديو والالعاب حجم الشاشه 12 بوصه سلم شكل روعه منافذ يو اس بي عدد2 منفذ رام مع منفذ hd الذاكره 32 كيكا نوع اس اس دي مع رام 4 كيكا وتكدر تطور الذاكره الى 256 عن طريق الرام اضافه الى كاميرا - بلوتوث - واي فاي



                            </p>
                            <br />
                            للحجز يرجى ملئ المعلومات اسفل */}
                          </div>

                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="الاسم" id="name" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>الاسم  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            {/* <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="المحافظة" id="gov" /> */}

                            <Form.Select id="gov" style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} >
                              <option value="none">اختر محافظة</option>
                              <option value="بغداد">بغداد</option>
                              <option value="بابل">بابل</option>
                              <option value="الأنبار">الأنبار</option>
                              <option value="البصرة">البصرة</option>
                              <option value="دهوك">دهوك</option>
                              <option value="القادسية">القادسية</option>
                              <option value="ديالى">ديالى</option>
                              <option value="ذي قار">ذي قار</option>
                              <option value="السليمانية">السليمانية</option>
                              <option value="صلاح الدين">صلاح الدين</option>
                              <option value="كركوك">كركوك</option>
                              <option value="كربلاء">كربلاء</option>
                              <option value="المثنى">المثنى</option>
                              <option value="النجف">النجف</option>
                              <option value="نينوى">نينوى</option>
                              <option value="واسط">واسط</option>
                              <option value="ميسان">ميسان</option>
                              <option value="اربيل">اربيل</option>
                              <option value="موصل">موصل</option>
                            </Form.Select>
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>المحافظة  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="المدينة" id="city" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>المدينة  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"number"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="الهاتف" id="phone" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>الهاتف  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>


                          </div>


                          <br></br>
                          <br></br>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>

                            <div id="spinnerdiv" style={{ display: 'none' }}>
                              <Button variant="primary" style={{ borderRadius: '35px' }} disabled>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                ...  تحميل
                              </Button>
                            </div>

                            {/* <button type="submit" id="submitbtn" className="button button-add-to-cart"
  onClick={() => {
    this.submit()
    // console.log(this.state.size, this.state.get_color2);
  }}
>احجز الان</button> */}

                            <Component initialState={{ isShown: false, isShown: false }}>
                              {({ setState, state }) => (
                                <Pane>
                                  <Dialog
                                    isShown={state.isShown}
                                    title="تأكيد"
                                    onCloseComplete={() => setState({ isShown: false })}
                                    confirmLabel="احجز الان"
                                    onConfirm={() => {
                                      this.submit()
                                      setState({ isShown: false })
                                    }}
                                    cancelLabel="رجوع"
                                  >
                                    <p style={{ textAlign: 'end' }}>
                                      رح يتصل بيك فريقنا لتأكيد الطلب
                                      <br></br>
                                      ❗❗ و مشكول الذمة اذا تحجز وما تستلم الطلب لان
                                      <br></br>
                                      👱‍♂️📦 لو تعرف انه اكو شخص رح يجهز الك هذا الطلب
                                      <br></br>
                                      🚗👴 واكو سايق رح يوصل الطلب للعنوان اللي مثبته
                                      <br></br>
                                      😥😭🙏 كل هذا جهد تعب ومصاريف والموضوع مو لعبة وخلي الله بين عيونك
                                      <br></br>
                                      ✔👍💪✔  وتأكد من نفسك قبل ما تحجز انه رح تستلم الطلب ومثبت المعلومات الصحيحة
                                      <br></br>
                                      ❤😍🥰🤩 واذا متأكد من كل شي شكرا الك لان حجز من خلالنا ونتعهد الك رح يوصلك الطلب باحسن صورة
                                      <br></br>
                                      🙏🌷🌷 شكرالك
                                    </p>                                  </Dialog>
                                  <button type="submit" id="submitbtn" className="button button-add-to-cart"
                                    onClick={() => {
                                      setState({ isShown: true })
                                      //    this.submit()
                                      // console.log(this.state.size, this.state.get_color2);
                                    }}
                                  >احجز الان</button>

                                </Pane>
                              )}
                            </Component>,
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Home;
